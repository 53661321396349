import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/_services/api.service";
import { GeneralService } from "src/app/_services/general.service";
import { currentDate, getToday, previousMonth } from "src/app/helpers";

@Component({
  selector: "app-manage-referral",
  templateUrl: "./manage-referral.component.html",
  styleUrls: ["./manage-referral.component.css"],
})
export class ManageReferralComponent implements OnInit {
  constructor(private _api: ApiService, private _general: GeneralService) {}
  isLoading: boolean = false;
  isFetching: boolean = false;
  filtered: boolean = false;
  isConfirming: boolean = false;
  collections: Array<any> = [];
  collections_details = [];
  orderLists = [];
  modals = {
    view_detail: "",
  };
  isLoadingDetail = false;
  merchant_ids: Array<string> = [];
  message: string = "";
  phones: Array<any> = [];
  searchedCollections: any[] = [];
  searchText: string;
  searched: boolean = false;
  showCloseIcon: boolean = false;
  user = JSON.parse(localStorage.getItem("user")) || {};
  is_searching: boolean = false;
  totalCount: number = 0;
  pagination: any = {
    page: 1,
    end: false,
  };
  filteredDetail: boolean = false;
  current_coupon_code: string = "";
  startDate = "";
  endDate = "";
  initialDate = currentDate();
  today = getToday();
  lastMonth = previousMonth();
  durations: Array<object> = [
    { title: "Duration", value: "duration" },
    { title: "Today", value: "today" },
    { title: "This Month", value: "this month" },
    { title: "Last Month", value: "last month" },
  ];

  ngOnInit() {
    this.getReferralOrders(this.initialDate, this.pagination.page);
  }

  async getReferralOrders(date: any, page: number) {
    try {
      if (page === 1) this.isLoading = true;
      else this.isFetching = true;
      const { data, end } = await this._api.getReferralOrders({
        ...date,
        page,
      });
      this.collections = data.map((r: any) => ({ ...r, checked: false }));
      this.pagination.end = end;
      this.isLoading = false;
      this.isFetching = false;
    } catch (error) {
      this.isLoading = false;
      this.isFetching = false;
      console.log(error);
    }
  }

  getItem({ item }) {
    this.getOrderDetails({
      ...this.initialDate,
      coupon_code: item.coupon_code,
    });
  }

  async getOrderDetails(payload: any) {
    try {
      this.modals.view_detail = "open";
      this.isLoadingDetail = true;
      this.current_coupon_code = payload.coupon_code;
      const { data } = await this._api.getReferralOrdersDetails(payload);
      this.collections_details = data;
      this.isLoadingDetail = false;
    } catch (error) {
      this.isLoading = false;
    }
  }
  async confirmReferralPayments() {
    try {
      if (!this.orderLists.length) return;
      this.isConfirming = true;
      const coupon_codes = this.orderLists.map(
        ({ coupon_code }) => +coupon_code
      );
      const res = await this._api.confirmReferralPayments({
        page: 1,
        coupon_codes,
      });
      this.collections = this.collections.filter(
        (item) => !coupon_codes.includes(item.coupon_code)
      );
      this.isConfirming = false;
      this.orderLists = [];
      this._general.notify({
        type: "success",
        message: `Payment confirmed!`,
        icon: "success",
      });
    } catch (error) {
      this.isConfirming = false;
      this._general.notify({
        type: "error",
        message: `Operation failed!`,
        icon: "error",
      });
    }
  }

  getcheckedCollections({ length, data }) {
    this.orderLists = [...this.orderLists, ...data].filter(
      (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );
  }

  searchCollection() {
    this.searched = true;
    this.searchedCollections = this.collections.filter((data) => {
      return (
        data.fullname.toLowerCase().includes(this.searchText.toLowerCase()) ||
        data.phone.includes(this.searchText)
      );
    });
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.showCloseIcon = !!input.value;
  }

  clearSearch(): void {
    this.searchText = "";
    const input = document.querySelector(".search") as HTMLInputElement;
    input.focus();
  }

  filterData({ start_date, end_date, page }) {
    this.filtered = true;
    this.startDate = start_date;
    this.endDate = end_date;
    this.getReferralOrders({ start_date, end_date }, page);
  }

  loadMore() {
    if (this.pagination.end) return;
    const date = {
      start_date: this.startDate || this.initialDate.start_date,
      end_date: this.endDate || this.initialDate.end_date,
    };
    this.getReferralOrders(date, ++this.pagination.page);
  }
  filterDataDetail(start_date: string, end_date: string, page) {
    this.filteredDetail = true;
    this.startDate = start_date;
    this.endDate = end_date;
    const payload = {
      start_date,
      end_date,
      coupon_code: this.current_coupon_code,
    };
    this.getOrderDetails(payload);
  }
}
