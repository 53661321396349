import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { formatCurrency } from "src/app/helpers";

@Component({
  selector: "app-manage-referral-table",
  templateUrl: "./manage-referral-table.component.html",
  styleUrls: ["./manage-referral-table.component.css"],
})
export class ManageReferralTableComponent implements OnInit {
  constructor() {}
  @Input() collections = [];
  @Output() item = new EventEmitter<any>();
  @Output() checkedCollections = new EventEmitter<any>();
  ngOnInit() {}

  sendItem(item: any) {
    this.item.emit({ item });
  }

  checkAll(event: any) {
    this.collections.forEach((x) => (x.checked = event.target.checked));
    const list = this.checkCollections();
    this.checkedCollections.emit(list);
  }

  checkOne(id: any) {
    this.collections.map((x) =>
      +x.id === +id ? { ...x, checked: !x.checked } : x
    );
    const list = this.checkCollections();
    this.checkedCollections.emit(list);
  }

  checkCollections() {
    let result = { length: 0, data: [] };
    this.collections.forEach((x) => {
      if (x.checked) {
        result.length++;
        result.data.push(x);
      }
    });
    return result;
  }

  formatCurrency(price: any) {
    return formatCurrency(price);
  }
}
